import { store } from "@/store/store";
import { BasicEmailMessage } from "@/shared/dtos/BasicEmailMessage";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "SendingEmailsModule",
  store,
  dynamic: true,
})
class SendingEmailsModule extends VuexModule {
  @Action
  public async SendTestEmail(SendingEmails: BasicEmailMessage) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(
      API.SendingEmails + "/TestMail",
      SendingEmails.toJson()
    );
  }
}
export default getModule(SendingEmailsModule);
