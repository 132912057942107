import { store } from "@/store/store";
import { servidor_smtp } from "@/shared/dtos/servidor_smtp";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "servidor_smtpModule",
  store,
  dynamic: true,
})
class servidor_smtpModule extends VuexModule {
  public servidor_smtps: servidor_smtp[] = [];
  public servidor_smtp: servidor_smtp = new servidor_smtp();

  @Action({ commit: "onGetservidor_smtps" })
  public async getservidor_smtps() {
    return await ssmHttpService.get(API.servidor_smtp);
  }

  @Action({ commit: "onGetservidor_smtp" })
  public async getservidor_smtp(id: any) {
    return await ssmHttpService.get(API.servidor_smtp + "/" + id);
  }

  @Action
  public async guardarservidor_smtp(servidor_smtp: servidor_smtp) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.servidor_smtp, servidor_smtp.toJson());
    this.getservidor_smtps();
  }

  @Action
  public async modificarservidor_smtp(servidor_smtp: servidor_smtp) {
    await ssmHttpService.put(
      API.servidor_smtp + "/" + servidor_smtp.id,
      servidor_smtp
    );
    this.getservidor_smtps();
  }

  @Action
  public async eliminarservidor_smtp(servidor_smtp: servidor_smtp) {
    await ssmHttpService.delete(
      API.servidor_smtp + "/" + servidor_smtp.id,
      null,
      false
    );
    this.getservidor_smtps();
  }

  @Mutation
  public onGetservidor_smtps(res: servidor_smtp[]) {
    this.servidor_smtps = res ? res.map((x) => new servidor_smtp(x)) : [];
    try {
      this.servidor_smtp = this.servidor_smtps[0];
    } catch (error) {}
  }

  @Mutation
  public onGetservidor_smtp(res: servidor_smtp) {
    this.servidor_smtp = new servidor_smtp(res);
  }
}
export default getModule(servidor_smtpModule);
