import { BaseDto } from "@/shared/dtos/base-dto";

export class servidor_smtp extends BaseDto {
  public mailSender!: string;
  public nombre_Enviador!: string;
  public usuario!: string;
  public pass!: string;
  public name_server_smtp!: string;
  public puerto_server_smtp!: number;
}
