


























































































import { Component, Vue } from "vue-property-decorator";
import servidor_smtpModule from "@/store/modules/servidor_smtp-module";
import SendingEmailsModule from "@/store/modules/SendingEmails-module";
import { BasicEmailMessage } from "@/shared/dtos/BasicEmailMessage";
import { authorizationService } from "@/shared/services/AuthorizationService";
@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export default class NotificacionesForm extends Vue {
  public email = new BasicEmailMessage();
  public dialogo_send_mail = false;
  public created() {
    servidor_smtpModule.getservidor_smtps();
    this.email.email = authorizationService.userName()!;
  }

  public get datasource() {
    return servidor_smtpModule.servidor_smtp;
  }

  public cancelar() {
    servidor_smtpModule.getservidor_smtps();
  }

  /**
   * aceptar
   */
  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        servidor_smtpModule.modificarservidor_smtp(this.datasource);
      }
    });
  }
  public test() {
    this.dialogo_send_mail = true;
  }
  public Sendtest() {
    SendingEmailsModule.SendTestEmail(this.email).then(
      () => (this.dialogo_send_mail = false)
    );
  }
}
